import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, defer, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, mergeAll, shareReplay, switchMap } from 'rxjs/operators';
import { Departament } from 'src/app/models/departament';
import { TypeAccident } from 'src/app/models/type-accident';
import { TypeSinister } from 'src/app/models/type-sinister';
import { environment, environmentExt } from 'src/environments/environment';
import { TokenService } from '../token.service';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: 'root'
})
export class RowsGeneralService {

  private baseUrl = environment.url;
  private baseUrlExt = environmentExt.url;
  cacheStateCases$: Observable<DataTablesResponse>

  /**
   * Variable para refrescar datos en cache lista de tipo de siniestros
   *
   * @author Joe Alejandro Sierra <joe.sierra@holdingvml.net> 2022-05-26
   */
  private readonly refreshTypeSinisterSubject = new Subject();

  /**
   * Variable para refrescar datos en cache lista de tipo de accidentes
   *
   * @author Joe Alejandro Sierra <joe.sierra@holdingvml.net> 2022-05-26
   */
  private readonly refreshTypeAccidentSubject = new Subject();

  /**
   * Variable para refrescar datos en cache lista de departamentos
   *
   * @author Joe Alejandro Sierra <joe.sierra@holdingvml.net> 2022-05-26
   */
  private readonly refreshDepartamentSubject = new Subject();

  /**
   * Variable para crear observable a partir de unión de observables, con el cual se podrá consultar el api de tipo de siniestros.
   * Esta varibale será usada como cache para mantener la información de tipo de siniestros.
   * Para actualizar la información se deberá usar refreshTypeAccidentSubject, evitando recargar la página.
   *
   * @author Joe Alejandro Sierra <joe.sierra@holdingvml.net> 2022-05-26
   */
  typeSinisters: Observable<TypeSinister[]> = of(
    this.getTypeSinisters([]).pipe(
      map((e) => e.data)
    ),
    defer(() => this.refreshTypeAccidentSubject.pipe(
      switchMap(() => this.getTypeSinisters([]).pipe(
        map((e) => e.data)
      )))
    )
  ).pipe(
    mergeAll(),
    shareReplay(1),
  );

  /**
   * Variable para crear observable a partir de unión de observables, con el cual se podrá consultar el api de tipo de accidentes.
   * Esta varibale será usada como cache para mantener la información de tipo de accidentes.
   * Para actualizar la información se deberá usar refreshTypeAccidentSubject, evitando recargar la página.
   *
   * @author Joe Alejandro Sierra <joe.sierra@holdingvml.net> 2022-05-26
   */
  typeAccidents: Observable<TypeAccident[]> = of(
    this.getTypeAccidents([]).pipe(
      map((e) => e.data)
    ),
    defer(() => this.refreshTypeSinisterSubject.pipe(
      switchMap(() => this.getTypeAccidents([]).pipe(
        map((e) => e.data)
      )))
    )
  ).pipe(
    mergeAll(),
    shareReplay(1),
  );

  /**
   * Variable para crear observable a partir de unión de observables, con el cual se podrá consultar el api de departamentos.
   * Esta varibale será usada como cache para mantener la información de departamentos.
   * Para actualizar la información se deberá usar refreshDepartamentSubject, evitando recargar la página.
   *
   * @author Joe Alejandro Sierra <joe.sierra@holdingvml.net> 2022-05-26
   */
  departaments: Observable<Departament[]> = of(
    this.getDepartaments([]).pipe(
      map((e) => e.data)
    ),
    defer(() => this.refreshDepartamentSubject.pipe(
      switchMap(() => this.getDepartaments([]).pipe(
        map((e) => e.data)
      )))
    )
  ).pipe(
    mergeAll(),
    shareReplay(1),
  );

  header(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ` + this.token.get())
    }
  }

  constructor(private http: HttpClient, private token: TokenService) { }

  findPlatedep(data) {
    return this.http.get<any>(`${this.baseUrl}/report-accident/find/${data}`, this.header());
  }

  findPlateRestdep(data) {
    return this.http.post<any>(`${this.baseUrl}/report-accident/find/rest/${data}`, this.header());
  }

  findPlate(data) {
    return this.http.get<any>(`${this.baseUrlExt}/report-accident/find/${data}`, this.header());
  }

  findPlateRest(data) {
    return this.http.post<any>(`${this.baseUrlExt}/report-accident/find/rest/${data}`, this.header());
  }

  getcompanyByname(data) {
    return this.http.get<any>(`${this.baseUrl}/report-accident/company/${data}`, this.header());
  }

  getcompanyByid(data) {
    return this.http.get<any>(`${this.baseUrl}/report-accident/companyid/${data}`, this.header());
  }

  getCases(nextPage, value, pageSize) {
    return this.http.get<any>(`${this.baseUrl}/cases?page=${nextPage}&value=${value}&pageSize=${pageSize}`, this.header());
  }
  getAllauroraCases(nextPage, value, pageSize) {
    return this.http.get<any>(`${this.baseUrl}/aurora-cases?page=${nextPage}&value=${value}&pageSize=${pageSize}`, this.header());
  }
  getAuroraCase(data) {
    return this.http.get<any>(`${this.baseUrl}/aurora-case/${data}`, this.header());
  }

  getCasesConcludedDep(data) {
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/UmEtjdVjlDQqeCmZyANXDEP`, data, this.header());
  }

  getCasesConcluded(nextPage, value, pageSize) {
    return this.http.post<any>(`${this.baseUrl}/UmEtjdVjlDQqeCmZyANX?page=${nextPage}&value=${value}&pageSize=${pageSize}`, this.header());
  }
  getCasesCalificatedDep(data) {
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/getCasesCalificated`, data, this.header());
  }

  getCasesCalificated(nextPage, value, pageSize) {
    return this.http.post<any>(`${this.baseUrl}/getCasesCalificated?page=${nextPage}&value=${value}&pageSize=${pageSize}`, this.header());
  }

  getAnswerCase(id) {
    return this.http.get<any>(`${this.baseUrl}/getAnswerCase/${id}`, this.header());
  }

  create(data) {
    return this.http.post(`${this.baseUrl}/report-accident/create`, data, this.header());
  }

  select(data): Promise<any> {
    return (this.http.post(`${this.baseUrl}/report-accident/select`, data, this.header()) as Observable<any>).toPromise();
  }
  insertpwa(data) {
    //return this.http.post(`${this.baseUrl}/pruebaApipwa`, data, this.header());
    return this.http.post(`${this.baseUrl}/insertLeoncy`, data, this.header());
  }

  formatNumber(depId: number, num: number): string {
    if (num < 10) {
      return depId.toString() + '00' + num.toString();
    } else if (num < 100) {
      return depId.toString() + '0' + num.toString();
    } else {
      return depId.toString() + num.toString();
    }
  }

  insertReportpwa(data) {
    return this.http.post(`${this.baseUrl}/insertReportpwa`, data, this.header());
  }

  getReportpwa(id) {
    return this.http.get<any>(`${this.baseUrl}/getReportpwa/${id}`, this.header());
  }

  update(data) {
    return this.http.post(`${this.baseUrl}/report-accident/update`, data, this.header());
  }

  getTypeSinisters(data): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/typesinisters`, data)
  }

  getTypeAccidents(data): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/75QlraeFQhZPbeWhGafw`, data)
  }

  getDepartaments(data): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/departaments`, data)
  }

  getdepartamentbyname(data) {
    return this.http.get<any>(`${this.baseUrl}/departamentbyname/${data}`, this.header());
  }

  getCitys(data): Promise<any> {
    return (this.http.post<DataTablesResponse>(`${this.baseUrl}/citys`, data) as Observable<any>).toPromise();
  }
  getCitysbydigipat(data) {
    return this.http.post(`${this.baseUrl}/getCitysbydigipat`, data, this.header());
  }

  getStateCases(data): Observable<DataTablesResponse> {
    if (!this.cacheStateCases$) {
      this.cacheStateCases$ = this.requestGetStateCases(data).pipe(
        shareReplay(1)
      )
    }
    return this.cacheStateCases$
  }

  requestGetStateCases(data): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/statecases`, data)
  }

  getDataReport(data) {
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/PnAlApSFCabSraS`, data, this.header());
  }

  getCountProcessCase() {
    return this.http.post<DataTablesResponse>(`${this.baseUrl}/asCabAsgAshaQehhDasjNASa1h!`, []);
  }

  changeStatusCase(data) {
    return this.http.post(`${this.baseUrl}/EsAsbAsgAshaQehhDasjNASa1h!`, data, this.header());
  }

  acceptAuroraCase(data) {
    return this.http.post(`${this.baseUrl}/acceptAuroraCase`, data, this.header());
  }

  leaveAuroraCase(data) {
    return this.http.post(`${this.baseUrl}/leaveAuroraCase`, data, this.header());
  }

  getQuestions(): Promise<any> {
    return (this.http.get(`${this.baseUrl}/As2PbAsgAshaQehhDasjNASa1h!`) as Observable<any>).toPromise();
  }

  getAnswer() {
    return this.http.get<[]>(`${this.baseUrl}/Poa0bAsgAshaQehhDasjNASa1h!`);
  }

  sendQuestionAnswer(data) {
    return this.http.post(`${this.baseUrl}/Aps1bAsgAshaQehhDasjNASa1h!`, data, this.header());
  }

  getDataReportRecordings(data) {
    return this.http.post(`${this.baseUrl}/vm6p6vrPNXEmJIVYpDDM`, data, this.header());
  }

  getDataReportRecordingsPending(data) {
    return this.http.post(`${this.baseUrl}/vm6p6vrPNXEmJIVYpDDA`, data, this.header());
  }

  allowCases() {
    return this.http.post(`${this.baseUrl}/YIkKO5MUC3SYon5mPgAS`, [], this.header());
  }

  deleteCase(data) {
    return this.http.post(`${this.baseUrl}/N3uPTcPOichrAYZG4TJ4`, data, this.header());
  }

}
