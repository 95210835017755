import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { CountAuroraService } from 'src/app/Services/count-aurora.service';
import { RowsGeneralService } from 'src/app/Services/report-accidents/rows-general.service';
import { TokenService } from 'src/app/Services/token.service';
import { UserInformationService } from 'src/app/Services/users/user-information.service';
import { AuroraCase } from 'src/app/models/aurora-case';
import { User } from 'src/app/models/user';


@Component({
  selector: 'app-aurora-cases',
  templateUrl: './aurora-cases.component.html',
  styleUrls: ['./aurora-cases.component.css']
})
export class AuroraCasesComponent implements OnInit {
  public userData: User
  nameUser: string = ''
  auroracases: AuroraCase[];
  loading: boolean = false
  pageSize: number = 10;
  listOfData: { data: AuroraCase[], total: number, current_page: number } = {} as any
  public user = { profile: null, permission: false };
  form: FormGroup = new FormGroup({
    value: new FormControl('')
  })

  constructor(
    private userInformationService: UserInformationService,
    private router: Router,
    private socket: Socket,
    public countAuroraService: CountAuroraService,
    private token: TokenService,
    private GeneralService: RowsGeneralService
  ) {
    this.webSockets()
  }

  ngOnInit(): void {
    this.loadData()
    this.form.controls['value'].valueChanges.subscribe(
      data => this.loadData(1, data)
    )
    this.checkUser();
    setTimeout(() => {
      this.updateRowvalor()
    }, 6000);
    this.userInformationService.userStatus.subscribe(data => { this.userData = data })

  }

  loadData(current_page: number = 1, value: string = ''): void {
    this.loading = true
    this.GeneralService.getAllauroraCases(current_page, value, this.pageSize).subscribe(data => {
      this.listOfData = data
      this.loading = false
      this.colored()
    })
  }

  webSockets() {
    this.socket.on('nueva_insercion', (data: any) => {
      console.log('Se ha recibido una nueva inserción:', data)
      this.loadData()
    })
    this.socket.on('paso_a_3', (data: any) => {
      console.log(data)
      this.loadData()
    })
    this.socket.on('paso_a_1', (data: any) => {
      console.log(data)
      this.loadData()
    })
    this.socket.on('paso_a_2', (data: any) => {
      console.log(data)
      this.loadData()
    })
  }


  enviarDatosAlNodeJS(id: string) {
    const datos = { mensaje: 'Hola desde Angular', id: id, nameUser: this.nameUser };
    // Enviar datos al servidor Node.js
    this.socket.emit('enviar_datos_al_nodejs', datos);
    // Navegar a la ruta específica después de enviar los datos
    this.router.navigateByUrl(`/report-accident-aurora/${id}`);
  }

  activarCaso(id:string) {
    const datos = { mensaje: 'le di volver', id: id  , nameUser:''};
    this.socket.emit('volver', datos);
    this.GeneralService.leaveAuroraCase(datos)
  }

  activarCasos(event: MouseEvent) {
    event.preventDefault();
    this.listOfData.data.forEach(element => {
      const datos = { mensaje: 'le di volver', id: element.id  , nameUser:''};
      this.socket.emit('volver', datos);
      this.GeneralService.leaveAuroraCase(datos)
    })
  }


  colored() {
    let p = this.countAuroraService.get()
    let q = parseInt(p)
    this.countAuroraService.getRowCount().subscribe((dta: number) => {
      const select = {
        colored: true
      }
      if (dta > q) {
        let resta = dta - q
        for (var i = 0; i < resta; i++) {
          const finalResult = Object.assign(this.listOfData.data[i], select)
          this.listOfData.data[i] = finalResult
        }
        setTimeout(() => {
          this.changecolorfalse()
        }, 4000);
      }
    })
  }

  updateRowvalor() {
    this.countAuroraService.getRowCount().subscribe((dta: number) => {
      let p = this.countAuroraService.get()
      let q = parseInt(p)
      if (dta > q) {
        this.countAuroraService.set(dta)
      }
    })
  }

  changecolorfalse() {
    this.listOfData.data.forEach(element => {
      element.colored = false
    });
  }


  checkUser() {
    this.token.searchUser().subscribe(
      data => this.fillData(data)
    );
  }
  fillData(data) {
    this.userInformationService.userStatus.subscribe(data => {
      this.nameUser = data.name
    })
    this.user.profile = data.user.profile_ID;
    if (this.user.profile == 1) {
      this.user.permission = true;
    }
  }

  adjustPageSize(pageSize: number): void {
    this.pageSize = pageSize;
    this.loadData();
  }

}
