<div>
  <div class="float-left">
    <h4>Inf. General de casos reportados desde Aurora </h4>
  </div>
</div>
<br>
<hr>
<nz-input-group [nzSuffix]="suffixIconSearch" [formGroup]="form" style="width: 650px;">
  <input type="text" #searchText nz-input
    placeholder="Buscar coincidencias (placa, fecha registro, tipo siniestro, tipo asistencia, cliente...), ej: AAA123"
    formControlName="value">
</nz-input-group>
<ng-template #suffixIconSearch>
  <i nz-icon nzType="search"></i>
</ng-template>
     &nbsp;&nbsp;
<button nz-tooltip="Activar todos los casos"(click)="activarCasos($event)" *ngIf="userData.profile_ID==1" class="btn btn-success btn-sm" >
  <i class="fas fa-edit"></i>
</button>
<nz-table [nzData]="listOfData.data" [nzFrontPagination]="false" [nzShowSizeChanger]="true" [nzTotal]="listOfData.total"
  (nzPageIndexChange)="loadData($event)" (nzPageSizeChange)="adjustPageSize($event)" nzLoading="{{loading}}">
  <thead>
    <tr>
      <!-- <th># Evento</th> -->
      <th>Fecha de Registro</th>
      <th>Placa</th>
      <th>Teléfono</th>
      <th>Cliente</th>
      <th>Dirección Siniestro</th>
      <th>Punto Referencia</th>
      <th>Tipo Siniestro</th>
      <th>Tipo Asistencia</th>
      <th>Departamento</th>
      <th>Ciudad</th>
      <th>Observaciones</th>
      <th style="width: 1px;">Acción</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let case of listOfData.data" [style.background]="case.colored ? '#76D7C4' : ''">
      <!-- <td>{{ case.id }}</td> -->
      <td>{{ case.registration_date}}</td>
      <td>{{ case.plate }}</td>
      <td>{{ case.interested_phone}}</td>
      <td>{{ case.interested_name }}</td>
      <td>{{ case.accident_address}} </td>
      <td>{{ case.reference_point }}</td>
      <td>{{ case.type_accident}}</td>
      <td>{{ case.typeof_assistance}}</td>
      <td>{{ case.departament }}</td>
      <td>{{ case.city }}</td>
      <td>{{ case.observations }}</td>
      <td>
        <div class="row justify-content-md-center align-items-center">
          <div class="btn-group" role="group">
            <small id="smallNameModify{{case.idC}}">{{ case.userModify }}</small>
          </div>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button nz-tooltip="Tomar Caso" [disabled]="case.state_case==3" id="idCase{{case.idC}}" class="btn btn-info btn-sm" (click)="enviarDatosAlNodeJS(case.id)">
              <i class="fas fa-edit"></i>
            </button>

            <button nz-tooltip="Activar Caso" *ngIf="userData.profile_ID==1"  id="idCase{{case.idC}}" class="btn btn-success btn-sm" (click)="activarCaso(case.id)">
              <i class="fas fa-edit"></i>
            </button>
          </div>
          <p>{{case.name_user}}</p>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>
<br><br>
<br><br>
<br><br>
<br><br>
<br><br>
<small>Pablo Developer.</small>
